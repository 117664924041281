import React from 'react';

import HeaderLegal from '../components/HeaderLegal';
import Layout from '../components/Layout';
import Footer from '../components/Footer';

import DiscordIcon from '../assets/icons/discord48.png';
import TwitterIcon from '../assets/icons/twitter48.png';
import FacebookIcon from '../assets/icons/facebook48.png';
import RedditIcon from '../assets/icons/reddit48.png';
import ButSteam200 from '../assets/icons/butsteam200.png';
import img_gameLogo540 from '../assets/img/actionsquad_540x300.png';

export default () => (
  <Layout>
    <HeaderLegal />

    <section className="page-section bg-black" id="home">
      <div className="container">
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <div className="centerlogo">
              <img src={img_gameLogo540} alt="Door Kickers: Action Squad" />
            </div>
          </div>

          <div className="col-lg-10 align-self-end">
            <h1 className="text-white font-weight-bold my-4 py-4">
              Contact Us
            </h1>
            <hr className="divider my-4" />
          </div>

          <div className="col-lg-10 text-center text-white">
            <p>Contact us on social media.</p>
          </div>

          <div className="small text-center text-white">
            <a
              href="https://discord.gg/PqyMGYv"
              target="_blank"
              title="PixelShard Discord"
              rel="noopener noreferrer"
            >
              <img
                alt="PixelShard Discord"
                src={DiscordIcon}
                className="text-white mb-4 mx-2"
                style={{ width: 48, height: 48 }}
              />
            </a>
            <a
              href="https://www.facebook.com/PixelShardLabs/"
              target="_blank"
              title="PixelShard Facebook"
              rel="noopener noreferrer"
            >
              <img
                alt="PixelShard Facebook"
                src={FacebookIcon}
                className="text-white mb-4 mx-2"
                style={{ width: 48, height: 48 }}
              />
            </a>
            <a
              href="https://twitter.com/pixelshardlabs"
              target="_blank"
              title="PixelShard Twitter"
              rel="noopener noreferrer"
            >
              <img
                alt="PixelShard Twitter"
                src={TwitterIcon}
                className="text-white mb-4 mx-2"
                style={{ width: 48, height: 48 }}
              />
            </a>
            <a
              href="https://www.reddit.com/r/ActionSquadGame/"
              target="_blank"
              title="PixelShard Reddit"
              rel="noopener noreferrer"
            >
              <img
                alt="PixelShard Reddit"
                src={RedditIcon}
                className="text-white mb-4 mx-2"
                style={{ width: 48, height: 48 }}
              />
            </a>
          </div>

          <div className="col-lg-10 text-center text-white">
            <p>
              You can also look for an answer or ask a question in the Steam
              Community Hub.<br></br>
              <br></br>
              <a
                href="https://steamcommunity.com/app/686200/discussions/"
                target="_blank"
                title="Action Squad Steam Community"
                rel="noopener noreferrer"
              >
                <img
                  alt="Action Squad Steam Community"
                  src={ButSteam200}
                  className="text-white mb-4 mx-2"
                />
              </a>
              <br></br>We'll get back to you as soon as possible.
            </p>
          </div>

          <div className="col-lg-10 text-center">
            <hr className="divider my-4" />
          </div>
        </div>
      </div>
    </section>

    <section className="page-section bg-black" id="footer">
      <div className="container"></div>
    </section>

    <Footer />
  </Layout>
);
